export const SET_WORD_LEVEL = "SET_WORD_LEVEL";
export const NEW_WORD = "NEW_WORD";
export const FETCHING_WORD = "FETCHING_WORD";
export const GET_NEXT_WORD = "GET_NEXT_WORD";
export const SETTING_WORD_KNOWLEDGE = "SETTING_WORD_KNOWLEDGE";
export const RESET_CARD = "RESET_CARD";
export const CREATE_WORD = "CREATE_WORD";
export const WEBAPI_ERROR = "WEBAPI_ERROR";
export const WEBAPI_ERROR_END = "WEBAPI_ERROR_END";
export const PRONOUNCE_WORD = "PRONOUNCE_WORD";
export const TOGGLE_PRONUNCIATION = "TOGGLE_PRONUNCIATION";
export const SET_USER = "SET_USER";
export const UNSET_USER = "UNSET_USER";
export const TRIGGER_SIGNIN = "TRIGGER_SIGNIN";
export const TRIGGER_SIGNOUT = "TRIGGER_SIGNOUT";
export const CHECK_SESSION = "CHECK_SESSION";
export const STACK_IS_EMPTY = "STACK_IS_EMPTY";
export const TOGGLE_BLUR = "TOGGLE_BLUR";
export const TOGGLE_SOUND_EFFECTS = "TOGGLE_SOUND_EFFECTS";
export const LIST_WORDS = "LIST_WORDS";
export const NEW_WORDS_LIST = "NEW_WORDS_LIST";
export const DELETE_WORD = "DELETE_WORD";
export const SET_SETTINGS = "SET_SETTINGS";
