import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { deleteWord, listWords } from "../redux/actions";
import { RootState } from "../redux/reducers";
import "./WordsList.css";
import EmptyList from "./EmptyList";

const wordsListConnector = connect(({ words }: RootState) => ({ words }), {
    deleteWord,
});

type WordsListProps = ConnectedProps<typeof wordsListConnector>;

const WordsList: React.FC<WordsListProps> = ({ words, deleteWord }) => {
    if (!words.loaded) {
        return <div>Loading...</div>;
    }

    if (words.list.length === 0) {
        return <EmptyList />;
    }

    return (
        <div className="tal">
            <Toolbar disableGutters>
                <h3 className="ptitle">Your words ({words.list.length})</h3>
            </Toolbar>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="tb">Word</TableCell>
                        <TableCell className="tb">Sentence</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {words.list.map(({ word, sentence, id }) => (
                        <TableRow key={`${id}`}>
                            <TableCell>{word}</TableCell>
                            <TableCell>{sentence}</TableCell>
                            <TableCell>
                                <Tooltip title="Delete" placement="right" arrow>
                                    <IconButton
                                        edge="end"
                                        aria-label="comments"
                                        onClick={() => deleteWord(id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

const WordsListConnected = wordsListConnector(WordsList);

const wordsListContainerConnector = connect(undefined, { listWords });
type WordsListContainerProps = ConnectedProps<
    typeof wordsListContainerConnector
>;

const WordsListContainer: React.FC<WordsListContainerProps> = ({
    listWords,
}) => {
    useEffect(() => {
        listWords();
    });
    return (
        <div className="WordsList-container">
            <div className="WordsList">
                <WordsListConnected />
            </div>
        </div>
    );
};

export default wordsListContainerConnector(WordsListContainer);
