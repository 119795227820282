import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { RootState } from "../redux/reducers";

const connector = connect(({ auth }: RootState) => ({ auth }));

interface OtherProps {
    [prop: string]: any;
}

type PrivateRouteProps = ConnectedProps<typeof connector> & OtherProps;

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    auth,
    children,
    ...rest
}) => {
    return (
        <>
            <Route
                {...rest}
                render={({ location }) =>
                    auth.isAuthenticated ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: location },
                            }}
                        />
                    )
                }
            />
        </>
    );
};

export default connector(PrivateRoute);
