import Button from "@material-ui/core/Button";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { triggerSignIn } from "../redux/actions";
import { RootState } from "../redux/reducers";
import illustration from "../images/illustration.svg";
import stack from "../images/stack.svg";
import "./Home.css";

const connector = connect(({ auth }: RootState) => ({ auth }), {
    triggerSignIn,
});

interface HomeOwnProps {
    signInAnonymously?: boolean;
}

type HomeProps = ConnectedProps<typeof connector> & HomeOwnProps;

const Home: React.FC<HomeProps> = ({
    auth,
    triggerSignIn,
    signInAnonymously = false,
}) => {
    if (auth.isAuthenticated) {
        const image = auth.user?.photoURL ? (
            <img className="photo" alt="profile" src={auth.user?.photoURL} />
        ) : null;
        return (
            <div className="Home-container">
                <div className="Home tac">
                    {image}
                    <h3>Welcome, {auth.user?.userName.split(" ")[0]}</h3>
                    <p className="ptl">
                        <Button
                            component={RouterLink}
                            to="/cards"
                            color="secondary"
                            variant="contained"
                        >
                            Start a flashcards session
                        </Button>
                    </p>
                </div>
            </div>
        );
    }
    return (
        <div className="Home-container Home-container--with-texture ">
            <div className="Home">
                <div className="row">
                    <div className="col">
                        <section className="paper pbm ">
                            <h1>Spaced Repetition</h1>
                            <h3 className="white-outline">
                                Use flashcards and spaced repetition to memorise
                                words spellings.
                            </h3>
                            <p className="white-outline">
                                We all know how English pronunciation differs so
                                much from written language. As a result, one
                                needs to remember the spelling of words by
                                heart.
                            </p>
                        </section>
                        <section className="paper paper-orange pal">
                            <h3>Features</h3>
                            <ul className="mtn ptn">
                                <li className="pbs">
                                    Pronounce words before displaying them
                                </li>
                                <li className="pbs">
                                    Start and stop sessions any time
                                </li>
                                <li className="pbs">
                                    Use pre-existing lists of words (not ready
                                    yet)
                                </li>
                            </ul>
                        </section>

                        <p className="ptl tac">
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                    triggerSignIn(signInAnonymously);
                                }}
                            >
                                Sign in with Google to start
                            </Button>
                        </p>
                    </div>
                    <div className="col tac">
                        <p>
                            <img
                                src={illustration}
                                className="illustration pal"
                                alt="child writing in front of a laptop"
                            ></img>
                        </p>
                    </div>
                </div>
                <div className="row row-reverse">
                    <div className="col">
                        <section className="paper pal paper-cyan">
                            <h3>How does it work?</h3>
                            <p>
                                As a parent you can use this application with
                                your children, though it is opened to anyone at
                                every age!
                            </p>
                            <ol>
                                <li className="pbs">Listen to the word</li>
                                <li className="pbs">Write each word</li>
                                <li className="pbs">Verify the spelling</li>
                                <li className="pbs">
                                    Mark whether it is correct or incorrect
                                </li>
                                <li className="pbs">
                                    Then let Space Out cleverly suggest the next
                                    word!
                                </li>
                            </ol>
                        </section>
                    </div>
                    <div className="col">
                        <section className="tac">
                            <p>
                                <img
                                    src={stack}
                                    className="illustration pal"
                                    alt="Stack of words being reordered"
                                ></img>
                            </p>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connector(Home);
