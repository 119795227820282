import { Middleware } from "redux";
import { webApiErrorEnd } from "../actions";
import { WEBAPI_ERROR } from "../actionTypes";
import { RootState } from "../reducers";

const ERROR_STATE_DELAY = 5000; // seconds

const webApiErrorTimer: Middleware<{}, RootState> = (store) => (next) => (
    action: Actions
) => {
    switch (action.type) {
        case WEBAPI_ERROR: {
            setTimeout(() => {
                store.dispatch(webApiErrorEnd());
            }, ERROR_STATE_DELAY);
            break;
        }
    }
    return next(action);
};
export default webApiErrorTimer;
