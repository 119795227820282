import { Middleware } from "redux";
import { getSettings, saveSettings } from "../../webApi";
import {
    SET_USER,
    TOGGLE_BLUR,
    TOGGLE_PRONUNCIATION,
    TOGGLE_SOUND_EFFECTS,
} from "../actionTypes";
import { RootState } from "../reducers";
import { setSettings } from "../actions";

const settings: Middleware<{}, RootState> = (store) => (next) => async (
    action
) => {
    let result = next(action);
    const { settings, auth } = store.getState();
    switch (action.type) {
        case TOGGLE_PRONUNCIATION:
        case TOGGLE_BLUR:
        case TOGGLE_SOUND_EFFECTS:
            if (auth.user) {
                saveSettings(auth.user.userId, settings);
            }
            break;
        case SET_USER:
            if (auth.user) {
                const settings = await getSettings(auth.user.userId);
                if (settings) {
                    store.dispatch(setSettings(settings));
                }
            }
    }

    return result;
};
export default settings;
