import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const { NODE_ENV } = process.env;

firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

const db = firebase.firestore();

if (NODE_ENV === "development" || NODE_ENV === "test") {
    db.settings({ host: "localhost:8080", ssl: false });
}

const getStack = async (
    userId: UserID
): Promise<firebase.firestore.DocumentReference[]> => {
    const userRef = db.collection("users").doc(userId);
    const userDoc = await userRef.get();

    if (!userDoc.exists) {
        return [];
    }

    const stack: firebase.firestore.DocumentReference[] = userDoc.data()?.stack;

    if (!stack) {
        return [];
    }

    return stack;
};

async function addToStack(
    userId: UserID,
    ref: firebase.firestore.DocumentReference
) {
    const previousStack = await getStack(userId);
    await db
        .collection("users")
        .doc(userId)
        .set(
            {
                stack: [ref, ...previousStack],
            },
            { merge: true }
        );
}

async function removeFromStack(
    userId: UserID,
    ref: firebase.firestore.DocumentReference
) {
    const { arrayRemove } = firebase.firestore.FieldValue;
    await db
        .collection("users")
        .doc(userId)
        .update({ stack: arrayRemove(ref) });
}

export const addWord = async (
    userId: UserID,
    { word, sentence }: Payload.Card
): Promise<void> => {
    const wordRef = await db
        .collection("users")
        .doc(userId)
        .collection("words")
        .add({
            value: word,
            sentence,
        });

    await addToStack(userId, wordRef);
};

export const getWords = async (userId: UserID): Promise<Payload.Word[]> => {
    const snapshot = await db
        .collection("users")
        .doc(userId)
        .collection("words")
        .get();
    return snapshot.docs.map((doc) => {
        const { value, sentence } = doc.data();
        return { word: value, sentence, id: doc.id };
    });
};

export const getWordsStackHead = async (
    userId: UserID
): Promise<WebApi.NextWordResult> => {
    try {
        const userDocSnapshot = await db.collection("users").doc(userId).get();
        const userDoc = userDocSnapshot.data();

        if (userDoc && userDoc["stack"] && userDoc["stack"].length > 0) {
            const head = await userDoc["stack"][0].get();
            const { value, sentence } = head.data();
            return {
                result: { word: value, sentence },
                error: null,
            };
        }

        return {
            result: null,
            error: null,
        };
    } catch (error) {
        if (error instanceof Error) {
            return {
                result: null,
                error,
            };
        }
        return {
            result: null,
            error: new Error("Unknown error"),
        };
    }
};

const indexForIncorrect = (len: number, seed = Math.random()) =>
    Math.trunc((1 + seed) * (len / 3));
const indexForMeh = (len: number, seed = Math.random()) =>
    Math.trunc((2 + seed) * (len / 3));
const indexForCorrect = (len: number, seed = Math.random()) =>
    Math.trunc((3 + seed) * (len / 3));

export const moveInStack = async (userId: UserID, level: Level) => {
    const previousStack = await getStack(userId);

    if (previousStack) {
        const [head, ...nextStack] = previousStack;
        const len = nextStack.length;
        switch (level) {
            case "CORRECT": {
                nextStack.splice(indexForCorrect(len), 0, head);
                break;
            }
            case "INCORRECT": {
                nextStack.splice(indexForIncorrect(len), 0, head);
                break;
            }
            case "MEH": {
                nextStack.splice(indexForMeh(len), 0, head);
                break;
            }
            default:
                throw new Error("Unkown level");
        }

        await db
            .collection("users")
            .doc(userId)
            .set({ stack: nextStack }, { merge: true });
    }
};

const provider = new firebase.auth.GoogleAuthProvider();

const anonymUser = {
    userName: "Anonym User",
    photoURL:
        "data:image/gif;base64,R0lGODdhAQABAPAAAP8AAAAAACwAAAAAAQABAAACAkQBADs",
};

interface SigninInfo {
    user?: Payload.User;
    error: null | any;
}

export const signIn = async (isAnonym: boolean): Promise<SigninInfo> => {
    try {
        await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL);

        if (isAnonym) {
            const result = await firebase.auth().signInAnonymously();
            const user = result.user;
            const userId = user?.uid;
            return {
                user: {
                    ...anonymUser,
                    userId,
                } as Payload.User,
                error: null,
            };
        } else {
            const result = await firebase.auth().signInWithPopup(provider);
            const user = result.user;
            const userId = user?.uid;
            const userName = user?.displayName;
            const photoURL = user?.photoURL;
            return {
                user: { userId, userName, photoURL } as Payload.User,
                error: null,
            };
        }
    } catch (error) {
        return {
            error,
        };
    }
};

export const signOut = async (): Promise<SigninInfo> => {
    try {
        await firebase.auth().signOut();
        return { error: null };
    } catch (error) {
        return { error };
    }
};

export const initAuthVerification = async (cb: any) =>
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            return cb({
                ...anonymUser,
                userName: user.displayName
                    ? user.displayName
                    : anonymUser.userName,
                userId: user.uid,
                photoURL: user.photoURL ? user.photoURL : anonymUser.photoURL,
            });
        } else {
            return cb(null);
        }
    });

export const deleteWord = async (
    userId: UserID,
    wordId: WordID
): Promise<any> => {
    try {
        const wordRef = db
            .collection("users")
            .doc(userId)
            .collection("words")
            .doc(wordId);
        await wordRef.delete();
        await removeFromStack(userId, wordRef);
        return { error: null };
    } catch (error) {
        return { error };
    }
};

export const saveSettings = async (userId: UserID, settings: State.Settings) =>
    await db.collection("users").doc(userId).set({ settings }, { merge: true });

export const getSettings = async (userId: UserID) => {
    const snapshot = await db.collection("users").doc(userId).get();
    return snapshot.exists ? snapshot.data()?.settings : undefined;
};
