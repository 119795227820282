import { WEBAPI_ERROR, WEBAPI_ERROR_END } from "../actionTypes";

const initialState = {
    error: null,
};

export default function webApiStatus(
    state: State.WebApiStatus = initialState,
    action: Actions
): State.WebApiStatus {
    switch (action.type) {
        case WEBAPI_ERROR:
            return {
                ...initialState,
                error: (action as Action.WebApiError).payload,
            };
        case WEBAPI_ERROR_END:
            return { ...initialState, error: null };
        default:
            return state;
    }
}
