import { applyMiddleware, createStore } from "redux";
import { checkSession } from "./actions";
import middleware from "./middleware";
import reducers from "./reducers";

const store = createStore(reducers, applyMiddleware(...middleware));

requestAnimationFrame(() => {
    store.dispatch(checkSession());
});

export default store;
