import { SET_WORD_LEVEL } from "../actionTypes";

const initialState = 0;

export default function score(
    state: State.Score = initialState,
    action: Actions
): State.Score {
    switch (action.type) {
        case SET_WORD_LEVEL: {
            const level = (action as Action.SetWordLevel).payload;
            switch (level) {
                case "CORRECT":
                    return state < 0 ? initialState : state + 1;
                case "MEH":
                    return state - 1;
                case "INCORRECT":
                    return state > 0 ? initialState : state - 1;
            }

            break;
        }
        default:
            return state;
    }
}
