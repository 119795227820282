import { combineReducers } from "redux";
import auth from "./auth";
import card from "./card";
import score from "./score";
import settings from "./settings";
import webApiStatus from "./webApiStatus";
import words from "./words";

const rootReducer = combineReducers({
    card,
    webApiStatus,
    settings,
    auth,
    words,
    score,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
