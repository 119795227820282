import { DELETE_WORD, NEW_WORDS_LIST } from "../actionTypes";

const initialState: State.Words = {
    loaded: false,
    list: [],
};

export default function words(
    state = initialState,
    action: Actions
): State.Words {
    switch (action.type) {
        case NEW_WORDS_LIST:
            return {
                ...state,
                list: (action as Action.NewWords).payload,
                loaded: true,
            };
        case DELETE_WORD: {
            return {
                ...state,
                list: state.list.filter(
                    ({ id }) => id !== (action as Action.DeleteWord).payload
                ),
            };
        }
        default:
            return state;
    }
}
