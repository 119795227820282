import auth from "./auth";
import logger from "./logger";
import pronounce from "./pronounce";
import settings from "./settings";
import webApiErrorTimer from "./webApiErrorTimer";
import words from "./words";

const { NODE_ENV } = process.env;

const middleware = [words, webApiErrorTimer, pronounce, auth, settings];

if (NODE_ENV === "development") {
    middleware.push(logger);
}

export default middleware;
