import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../redux/reducers";
import "./WebApiStatus.css";

const connector = connect(({ webApiStatus }: RootState) => ({ webApiStatus }));

type WebApiProps = ConnectedProps<typeof connector>;

const WebApiStatus: React.FC<WebApiProps> = ({ webApiStatus }) => {
    return (
        <Snackbar open={webApiStatus.error !== null}>
            <Alert severity="warning">Something's wrong with the api</Alert>
        </Snackbar>
    );
};

export default connector(WebApiStatus);
