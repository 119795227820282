import {
    FETCHING_WORD,
    NEW_WORD,
    RESET_CARD,
    SETTING_WORD_KNOWLEDGE,
    STACK_IS_EMPTY,
} from "../actionTypes";

const initialState: State.Card = {
    loading: true,
    content: undefined,
    stackIsEmpty: true,
};

export default function card(
    state = initialState,
    action: Actions
): State.Card {
    switch (action.type) {
        case FETCHING_WORD:
        case SETTING_WORD_KNOWLEDGE:
            return { ...state, loading: true };
        case NEW_WORD:
            return {
                ...state,
                content: (action as Action.NewWord).payload,
                loading: false,
                stackIsEmpty: false,
            };
        case RESET_CARD:
            return { ...initialState };
        case STACK_IS_EMPTY:
            return { ...state, stackIsEmpty: true, loading: false };
        default:
            return state;
    }
}
