import { Middleware } from "redux";
import { initAuthVerification, signIn, signOut } from "../../webApi";
import { setUser, unsetUser, webApiError } from "../actions";
import { CHECK_SESSION, TRIGGER_SIGNIN, TRIGGER_SIGNOUT } from "../actionTypes";
import { RootState } from "../reducers";

let unsubscribeAuthVerification: firebase.Unsubscribe | undefined = undefined;

const auth: Middleware<{}, RootState> = (store) => (next) => async (
    action: Actions
) => {
    switch (action.type) {
        case TRIGGER_SIGNIN: {
            const { isAnonym } = (action as Action.TriggerSignIn).payload;
            const { user, error } = await signIn(isAnonym);

            if (error) {
                store.dispatch(webApiError(error));
            } else {
                store.dispatch(setUser(user as Payload.User));
            }
            break;
        }
        case TRIGGER_SIGNOUT: {
            const { error } = await signOut();
            if (error) {
                store.dispatch(webApiError(error));
            } else {
                store.dispatch(unsetUser());
            }
            break;
        }
        case CHECK_SESSION: {
            if (unsubscribeAuthVerification !== undefined) {
                unsubscribeAuthVerification();
            }
            unsubscribeAuthVerification = await initAuthVerification(
                (user: any) => {
                    if (user) {
                        store.dispatch(setUser(user as Payload.User));
                    } else {
                        store.dispatch(unsetUser());
                    }
                }
            );
            break;
        }
    }
    return next(action);
};

export default auth;
