import React from "react";
import empty from "../icons/empty.svg";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import "./EmptyList.css";

const EmptyList = () => (
    <div className="EmptyList tac">
        <p>
            <img className="icon" alt="Empty box" src={empty} />
        </p>
        <p>Oh no, your list is empty!</p>
        <p>
            <Button
                color="secondary"
                variant="contained"
                component={RouterLink}
                to="/words/create"
            >
                Add words
            </Button>
        </p>
    </div>
);

export default EmptyList;
