import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CardContainer from "./CardContainer";
import CreateWord from "./CreateWord";
import Home from "./Home";
import Navigation from "./Navigation";
import PrivateRoute from "./PrivateRoute";
import Score from "./Score";
import WebApiStatus from "./WebApiStatus";
import WordsList from "./WordsList";

const AppRouter: React.FC = () => {
    return (
        <Router>
            <>
                <Navigation />
                <WebApiStatus />
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/signInAnonymously">
                        <Home signInAnonymously={true} />
                    </Route>
                    <PrivateRoute path="/cards" exact>
                        <CardContainer />
                        <Score />
                    </PrivateRoute>
                    <PrivateRoute path="/words/create">
                        <CreateWord />
                    </PrivateRoute>
                    <PrivateRoute path="/words/list">
                        <WordsList />
                    </PrivateRoute>
                </Switch>
            </>
        </Router>
    );
};

export default AppRouter;
