import { Button } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import useSound from "use-sound";
import { createWord } from "../redux/actions";
import { RootState } from "../redux/reducers";
import "./CreateWord.css";

const dingSound = require("../sounds/ding.mp3");

const mapStateToProps = ({ settings }: RootState) => ({ settings });
const mapDispatchToProps = { createWord };
const connector = connect(mapStateToProps, mapDispatchToProps);

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type CreateWordProps = ConnectedProps<typeof connector>;

const initialState = {
    word: "",
    sentence: "",
    submitable: false,
    submited: false,
};

const CreateWord: React.FC<CreateWordProps> = ({ createWord, settings }) => {
    const wordInputEl = useRef<HTMLInputElement>(null);

    const [state, setState] = useState(initialState);
    const [playDing] = useSound(dingSound);

    useEffect(() => {
        let deferredFocus = setTimeout(() => {
            if (wordInputEl && wordInputEl.current) {
                wordInputEl.current.focus();
            }
        }, 300);
        return () => {
            clearTimeout(deferredFocus);
        };
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { word, sentence } = state;
        createWord({ word, sentence });
        setState({ ...state, submited: true });
        e.currentTarget.reset();
        if (settings.soundEffectsOn) {
            playDing();
        }
        setTimeout(() => {
            setState(initialState);
            if (wordInputEl && wordInputEl.current) {
                wordInputEl.current.focus();
            }
        }, 500);
    };

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const { name, value } = target;
        if (["word", "sentence"].includes(name)) {
            const nextState = {
                ...state,
                [name]: value,
            };
            return setState({
                ...nextState,
                submitable: nextState.sentence !== "" && nextState.word !== "",
            });
        }
    };

    const formClassName = `CreateWord ${
        state.submited ? "CreateWord--empty" : ""
    }`;

    return (
        <>
            <div className="CreateWord-container">
                <form className={formClassName} onSubmit={handleSubmit}>
                    <p>
                        <input
                            ref={wordInputEl}
                            type="text"
                            name="word"
                            placeholder="Word"
                            onChange={handleChange}
                        ></input>
                    </p>
                    <p>
                        <input
                            type="text"
                            name="sentence"
                            placeholder="Example sentence"
                            onChange={handleChange}
                        ></input>
                    </p>
                    <p>
                        <Button
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            disabled={!state.submitable}
                        >
                            Add
                        </Button>
                    </p>
                </form>
            </div>
            <Snackbar open={state.submited}>
                <Alert severity="success">Word added</Alert>
            </Snackbar>
        </>
    );
};

export default connector(CreateWord);
