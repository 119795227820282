import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { triggerSignOut } from "../redux/actions";
import { RootState } from "../redux/reducers";
import "./Navigation.css";
import Settings from "./Settings";

const connector = connect(({ auth }: RootState) => ({ auth }), {
    triggerSignOut,
});

type NavigationProps = ConnectedProps<typeof connector>;

const Navigation: React.FC<NavigationProps> = ({ auth, triggerSignOut }) => {
    const [settingsVisible, setSettingsVisible] = useState(false);

    if (auth.isAuthenticated) {
        return (
            <>
                <nav className="Navigation">
                    <ul>
                        <li>
                            <Link className="Navigation--title" to="/">
                                Spaced Repetition
                            </Link>
                        </li>
                        <li>
                            <Link className="Navigation--link" to="/cards">
                                Flashcards
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="Navigation--link"
                                to="/words/create"
                            >
                                Add words
                            </Link>
                        </li>
                        <li>
                            <Link className="Navigation--link" to="/words/list">
                                Words list
                            </Link>
                        </li>
                        <li>
                            <button
                                className="Navigation--link"
                                onClick={() => setSettingsVisible(true)}
                            >
                                Settings
                            </button>
                        </li>
                        <li>
                            <button
                                className="Navigation--link"
                                onClick={() => triggerSignOut()}
                            >
                                Sign out
                            </button>
                        </li>
                    </ul>
                </nav>
                <Settings
                    onClose={() => setSettingsVisible(false)}
                    open={settingsVisible}
                />
            </>
        );
    }

    return <nav className="Navigation"></nav>;
};

export default connector(Navigation);
