import { Middleware } from "redux";
import { NEW_WORD, PRONOUNCE_WORD } from "../actionTypes";
import { RootState } from "../reducers";

const pronounce: Middleware<{}, RootState> = (store) => (next) => (action) => {
    const { SpeechSynthesisUtterance, speechSynthesis } = window;
    const voice = speechSynthesis
        .getVoices()
        .filter((v) => v.name === "Google UK English Female")[0];

    const voiceRate = 0.8;

    const { type, payload } = action;

    switch (type) {
        case PRONOUNCE_WORD:
        case NEW_WORD: {
            const state = store.getState();
            const { word, sentence } = payload;
            const speech = [word, sentence, "the word is", word];

            speechSynthesis.cancel();

            if (!state.settings.autoPronunciation && type === NEW_WORD) {
                break;
            }

            if (speech.every((w) => w !== "")) {
                speech.forEach(async (w) => {
                    const sayThis = new SpeechSynthesisUtterance(`${w}`);
                    sayThis.voice = voice;
                    sayThis.rate = voiceRate;
                    speechSynthesis.speak(sayThis);
                    speechSynthesis.pause();
                    setTimeout(() => speechSynthesis.resume(), 1200);
                });
            }
            break;
        }
    }

    return next(action);
};
export default pronounce;
