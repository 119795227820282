import { Middleware } from "redux";
import {
    addWord,
    deleteWord,
    getWords,
    getWordsStackHead,
    moveInStack,
} from "../../webApi";
import {
    fetchingWord,
    getNextWord,
    newWord,
    newWordslist,
    settingWordKnowledge,
    stackIsEmpty,
    webApiError,
} from "../actions";
import {
    CREATE_WORD,
    DELETE_WORD,
    GET_NEXT_WORD,
    LIST_WORDS,
    SET_WORD_LEVEL,
} from "../actionTypes";
import { RootState } from "../reducers";

const wordsMW: Middleware<{}, RootState> =
    (store) => (next) => async (action: Actions) => {
        const { auth } = store.getState();

        switch (action.type) {
            case CREATE_WORD: {
                if (auth.user) {
                    addWord(
                        auth.user.userId,
                        (action as Action.CreateWord).payload
                    );
                }
                break;
            }

            case DELETE_WORD: {
                if (auth.user) {
                    const { error } = await deleteWord(
                        auth.user.userId,
                        (action as Action.DeleteWord).payload
                    );
                    if (error) {
                        store.dispatch(webApiError(error));
                    }
                }
                break;
            }

            case GET_NEXT_WORD: {
                store.dispatch(fetchingWord());
                if (auth.user) {
                    const { result, error } = await getWordsStackHead(
                        auth.user.userId
                    );
                    if (error) {
                        store.dispatch(webApiError(error));
                    } else {
                        if (result) {
                            store.dispatch(newWord(result));
                        } else {
                            store.dispatch(stackIsEmpty());
                        }
                    }
                }
                break;
            }

            case SET_WORD_LEVEL: {
                store.dispatch(settingWordKnowledge());

                if (auth.user) {
                    await moveInStack(
                        auth.user.userId,
                        (action as Action.SetWordLevel).payload
                    );
                }
                store.dispatch(getNextWord());
                break;
            }

            case LIST_WORDS: {
                if (auth.user) {
                    const words = await getWords(auth.user.userId);
                    const orderedWords = words.sort(
                        (a: Payload.Word, b: Payload.Word) =>
                            a["word"].localeCompare(b["word"])
                    );
                    store.dispatch(newWordslist(orderedWords));
                }
                break;
            }
        }

        return next(action);
    };

export default wordsMW;
