import Tooltip from "@material-ui/core/Tooltip";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import useSound from "use-sound";
import correct from "../icons/correct.svg";
import done from "../icons/done.svg";
import eye from "../icons/eye.svg";
import handwriting from "../icons/handwriting.svg";
import hide from "../icons/hide.svg";
import incorrect from "../icons/incorrect.svg";
import meh from "../icons/meh.svg";
import speaker from "../icons/speaker.svg";
import { pronounceWord, resetCard, setWordLevel } from "../redux/actions";
import { RootState } from "../redux/reducers";
import "./Card.css";
import EmptyList from "./EmptyList";

const correctSound = require("../sounds/correct.mp3");
const mehSound = require("../sounds/meh.mp3");
const incorrectSound = require("../sounds/incorrect.mp3");

const LevelButtons = ({ setLevel }: { setLevel: Function }) => (
    <>
        <Tooltip title="Correct">
            <button className="mrm mlm" onClick={() => setLevel("CORRECT")}>
                <img className="icon" alt="Correct" src={correct} />
            </button>
        </Tooltip>
        <Tooltip title="Meh">
            <button className="mrm mlm" onClick={() => setLevel("MEH")}>
                <img className="icon" alt="Meh" src={meh} />
            </button>
        </Tooltip>
        <Tooltip title="Incorrect">
            <button className="mrm mlm" onClick={() => setLevel("INCORRECT")}>
                <img className="icon" alt="Incorrect" src={incorrect} />
            </button>
        </Tooltip>
    </>
);

const cardsConnector = connect(
    ({ card, settings }: RootState) => ({ card, settings }),
    {
        setWordLevel,
        resetCard,
        pronounceWord,
    }
);
type CardProps = ConnectedProps<typeof cardsConnector>;

const Card: React.FC<CardProps> = ({
    card,
    settings,
    setWordLevel,
    pronounceWord,
}) => {
    const cardClassName = `Card ${card.loading ? "Card--loading" : ""}`;
    const { content, stackIsEmpty } = card;

    const [localBlurState, setLocalBlurState] = useState(settings.autoBlur);
    const [displayWritingPrompt, setDisplayWritingPrompt] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState(
        undefined as Level | undefined
    );
    const [playCorrect] = useSound(correctSound);
    const [playMeh] = useSound(mehSound);
    const [playIncorrect] = useSound(incorrectSound);

    useEffect(() => {
        setLocalBlurState(settings.autoBlur);
        return () => setLocalBlurState(settings.autoBlur);
    }, [card, settings]);

    if (stackIsEmpty) {
        return (
            <div className={cardClassName}>
                <EmptyList />
            </div>
        );
    }

    if (content === undefined) {
        return <div className={cardClassName}>Loading...</div>;
    }

    const { word, sentence } = content;

    if (displayWritingPrompt) {
        return (
            <div className={cardClassName}>
                <div>
                    <h2>{word}</h2>
                    <p>{sentence}</p>
                </div>
                <p className="ptl">
                    <Tooltip title="Next word">
                        <button
                            className="mrm mlm"
                            onClick={() => {
                                setWordLevel(selectedLevel!);
                                setDisplayWritingPrompt(false);
                            }}
                        >
                            <img className="icon" alt="Done" src={done} />
                        </button>
                    </Tooltip>
                </p>
                <p className="writedown">
                    <span>Write down the word</span>
                    <img
                        className="icon icons--small"
                        alt="Correct"
                        src={handwriting}
                    />
                </p>
            </div>
        );
    }

    const setLevel = (level: Level): void => {
        switch (level) {
            case "CORRECT": {
                if (settings.soundEffectsOn) {
                    playCorrect();
                }
                setWordLevel("CORRECT");
                setDisplayWritingPrompt(false);
                break;
            }
            case "MEH": {
                if (settings.soundEffectsOn) {
                    playMeh();
                }
                setSelectedLevel("MEH");
                setDisplayWritingPrompt(true);
                break;
            }
            case "INCORRECT": {
                if (settings.soundEffectsOn) {
                    playIncorrect();
                }
                setSelectedLevel("INCORRECT");
                setDisplayWritingPrompt(true);
                break;
            }
        }
    };

    return (
        <div className={cardClassName}>
            <ul className="helpers">
                <li>
                    <Tooltip title="Pronounce the word">
                        <button
                            onClick={() => pronounceWord({ word, sentence })}
                        >
                            <img
                                className="icon icon--small"
                                alt="Pronounce the word"
                                title="Pronounce the word"
                                src={speaker}
                            />
                        </button>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip title="Toggle blurriness">
                        <button
                            onClick={() => setLocalBlurState(!localBlurState)}
                        >
                            <img
                                className="icon icon--small"
                                alt="Toggle blur"
                                title="Toggle blur"
                                src={localBlurState ? eye : hide}
                            />
                        </button>
                    </Tooltip>
                </li>
            </ul>
            <div className={`blurrable ${localBlurState ? "blurred" : ""}`}>
                <h2>{word}</h2>
                <p>{sentence}</p>
            </div>
            <p className="ptl">
                <LevelButtons setLevel={setLevel} />
            </p>
        </div>
    );
};

export default cardsConnector(Card);
