import { SET_USER, UNSET_USER } from "../actionTypes";

const initialState = {
    user: null,
    isAuthenticated: false,
};

export default function auth(
    state: State.Auth = initialState,
    action: Actions
): State.Auth {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: (action as Action.SetUser).payload,
                isAuthenticated: true,
            };
        case UNSET_USER:
            return { ...initialState };
        default:
            return state;
    }
}
