import {
    CHECK_SESSION,
    CREATE_WORD,
    DELETE_WORD,
    FETCHING_WORD,
    GET_NEXT_WORD,
    LIST_WORDS,
    NEW_WORD,
    NEW_WORDS_LIST,
    PRONOUNCE_WORD,
    RESET_CARD,
    SETTING_WORD_KNOWLEDGE,
    SET_SETTINGS,
    SET_USER,
    SET_WORD_LEVEL,
    STACK_IS_EMPTY,
    TOGGLE_BLUR,
    TOGGLE_PRONUNCIATION,
    TOGGLE_SOUND_EFFECTS,
    TRIGGER_SIGNIN,
    TRIGGER_SIGNOUT,
    UNSET_USER,
    WEBAPI_ERROR,
    WEBAPI_ERROR_END,
} from "./actionTypes";

export const setWordLevel = (level: Level): Action.SetWordLevel => ({
    type: SET_WORD_LEVEL,
    payload: level,
});
export const fetchingWord = (): Action.FetchingWord => ({
    type: FETCHING_WORD,
});
export const newWord = (payload: Payload.Card): Action.NewWord => ({
    type: NEW_WORD,
    payload,
});
export const getNextWord = (): Action.GetInitial => ({
    type: GET_NEXT_WORD,
});
export const settingWordKnowledge = (): Action.SettingWordKnowledge => ({
    type: SETTING_WORD_KNOWLEDGE,
});
export const resetCard = (): Action.ResetCard => ({
    type: RESET_CARD,
});
export const createWord = (payload: Payload.Card): Action.CreateWord => ({
    type: CREATE_WORD,
    payload,
});
export const webApiError = (error: Error): Action.WebApiError => ({
    type: WEBAPI_ERROR,
    payload: error,
});
export const webApiErrorEnd = (): Action.WebApiErrorEnd => ({
    type: WEBAPI_ERROR_END,
});
export const pronounceWord = (payload: Payload.Card): Action.PronounceWord => ({
    type: PRONOUNCE_WORD,
    payload,
});
export const togglePronunciation = (): Action.TogglePronunciation => ({
    type: TOGGLE_PRONUNCIATION,
});
export const triggerSignIn = (isAnonym: boolean): Action.TriggerSignIn => ({
    type: TRIGGER_SIGNIN,
    payload: { isAnonym },
});
export const triggerSignOut = (): Action.TriggerSignOut => ({
    type: TRIGGER_SIGNOUT,
});
export const setUser = (payload: Payload.User): Action.SetUser => ({
    type: SET_USER,
    payload,
});
export const unsetUser = (): Action.UnsetUser => ({
    type: UNSET_USER,
});
export const checkSession = (): Action.CheckSession => ({
    type: CHECK_SESSION,
});
export const stackIsEmpty = (): Action.StackIsEmpty => ({
    type: STACK_IS_EMPTY,
});
export const toggleBlur = (): Action.ToggleBlur => ({
    type: TOGGLE_BLUR,
});
export const toggleSoundEffects = (): Action.ToggleSoundEffects => ({
    type: TOGGLE_SOUND_EFFECTS,
});
export const listWords = (): Action.ListWords => ({
    type: LIST_WORDS,
});
export const newWordslist = (payload: Payload.Word[]): Action.NewWords => ({
    type: NEW_WORDS_LIST,
    payload,
});
export const deleteWord = (payload: string): Action.DeleteWord => ({
    type: DELETE_WORD,
    payload,
});
export const setSettings = (payload: State.Settings): Action.SetSettings => ({
    type: SET_SETTINGS,
    payload,
});
