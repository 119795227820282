import {
    SET_SETTINGS,
    TOGGLE_BLUR,
    TOGGLE_PRONUNCIATION,
    TOGGLE_SOUND_EFFECTS,
} from "../actionTypes";

const initialState = {
    autoBlur: false,
    autoPronunciation: false,
    soundEffectsOn: false,
};

export default function settings(
    state: State.Settings = initialState,
    action: Actions
): State.Settings {
    switch (action.type) {
        case TOGGLE_BLUR:
            return { ...state, autoBlur: !state.autoBlur };
        case TOGGLE_PRONUNCIATION:
            return { ...state, autoPronunciation: !state.autoPronunciation };
        case TOGGLE_SOUND_EFFECTS:
            return { ...state, soundEffectsOn: !state.soundEffectsOn };
        case SET_SETTINGS:
            return { ...state, ...(action as Action.SetSettings).payload };
        default:
            return state;
    }
}
