import LinearProgress from "@material-ui/core/LinearProgress";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../redux/reducers";
import "./Score.css";

const connector = connect(({ score }: RootState) => ({ score }));
type ScoreProps = ConnectedProps<typeof connector>;

const emojis = [
    "👿",
    "🙀",
    "😼",
    "🐱",
    "😸",
    "😻",
    "😈",
    "🔥",
    "🦄",
    "🥳",
    "🎉",
    "💃",
    "🕺🏼",
    "👍🏻",
    "🤩",
    "🎠",
];

const emojisLen = emojis.length;
const emojisNeutralIdx = 3;

const emojisForScore = (score: State.Score) => {
    if (score + emojisNeutralIdx >= emojisLen) {
        return emojis[emojisLen - 1];
    }
    if (score < -1 * emojisNeutralIdx) {
        return emojis[0];
    }
    return emojis[score + emojisNeutralIdx];
};

const Score: React.FC<ScoreProps> = ({ score }) => {
    const progress = Math.min(score * 10, 100);

    return (
        <div className="Score-container">
            <div className="Score">
                <p className="tac">
                    {emojisForScore(score)}
                    <LinearProgress
                        className="progress"
                        variant="determinate"
                        value={progress}
                    />
                </p>
            </div>
        </div>
    );
};

export default connector(Score);
