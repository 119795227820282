import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import eyeIcon from "../icons/eye.svg";
import speakerIcon from "../icons/speaker.svg";
import starsIcon from "../icons/stars.svg";
import {
    toggleBlur,
    togglePronunciation,
    toggleSoundEffects,
} from "../redux/actions";
import { RootState } from "../redux/reducers";
import "./Settings.css";

const connector = connect(
    ({ settings }: RootState) => ({
        settings,
    }),
    {
        toggleBlur,
        togglePronunciation,
        toggleSoundEffects,
    }
);

type SettingsProps = ConnectedProps<typeof connector> & {
    open: boolean;
    onClose: Function;
};

const Settings: React.FC<SettingsProps> = ({
    settings,
    toggleBlur,
    togglePronunciation,
    toggleSoundEffects,
    open = false,
    onClose = () => {},
}) => {
    return (
        <Drawer anchor="right" open={open} onClose={() => onClose(false)}>
            <div className="Settings">
                <h3 className="title">Settings</h3>
                <List>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>
                            <img
                                className="icon"
                                alt="Pronounce words"
                                title="Pronounce words"
                                src={speakerIcon}
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="switch-list-auto-pronunciation"
                            primary="Auto-pronunciation"
                            secondary={
                                <span
                                    className="prm"
                                    style={{ display: "inline-block" }}
                                >
                                    The words are pronounced by default when
                                    flashcards are displayed. This option allows
                                    non-native speakers to hear the correct
                                    accent.
                                </span>
                            }
                        />
                        <ListItemSecondaryAction>
                            <Switch
                                checked={settings.autoPronunciation}
                                onChange={() => {
                                    togglePronunciation();
                                }}
                                edge="end"
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>
                            <img
                                className={`icon ${
                                    settings.autoBlur ? "blurred" : ""
                                }`}
                                alt="Blur words"
                                title="Blur words"
                                src={eyeIcon}
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="switch-list-auto-blur"
                            primary="Auto-blur"
                            secondary={
                                <span
                                    className="prm"
                                    style={{ display: "inline-block" }}
                                >
                                    Words are blurred by default on flashcards.
                                    Ideally, combined with automatic
                                    pronunciation, this option allows the
                                    learner to use this application
                                    autonomously.
                                </span>
                            }
                        />
                        <ListItemSecondaryAction>
                            <Switch
                                checked={settings.autoBlur}
                                onChange={() => {
                                    toggleBlur();
                                }}
                                edge="end"
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>
                            <img
                                className="icon"
                                alt="Sound on card change"
                                title="Sound on card change"
                                src={starsIcon}
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="switch-list-sound-effects"
                            primary="Sound effects"
                            secondary={
                                <span
                                    className="prm"
                                    style={{ display: "inline-block" }}
                                >
                                    Sound effects are triggered when clicking on
                                    flashcards buttons.
                                </span>
                            }
                        />
                        <ListItemSecondaryAction>
                            <Switch
                                checked={settings.soundEffectsOn}
                                onChange={() => {
                                    toggleSoundEffects();
                                }}
                                edge="end"
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </div>
        </Drawer>
    );
};

export default connector(Settings);
