import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getNextWord, resetCard } from "../redux/actions";
import Card from "./Card";
import "./CardContainer.css";

const cardsContainerConnector = connect(undefined, { getNextWord, resetCard });
type CardContainerProps = ConnectedProps<typeof cardsContainerConnector>;

const CardContainer: React.FC<CardContainerProps> = ({
    getNextWord,
    resetCard,
}) => {
    useEffect(() => {
        getNextWord();
        return () => {
            resetCard();
        };
    });
    return (
        <div className="Card-container">
            <Card />
        </div>
    );
};

export default cardsContainerConnector(CardContainer);
